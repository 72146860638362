import React, { Component } from 'react';
import {
    // Container,
    Row,
    Col,
    //View,
    MDBJumbotron,
    //MDBCol, MDBRow, 
    MDBContainer,
    //MDBBtn,
    //MDBCard, MDBCardBody, MDBCardTitle, MDBCardText,
} from 'mdbreact';

// import ProfileCard from '../components/ProfileCard'
import Profile from '../components/Profile'
import ProfileShort from '../components/ProfileShort'


const ColoredLine = ({  }) => (
    <hr
        style={{
            color: "white",
            backgroundColor: "white",
            height: 8
        }}
    />
);

const hstyle = { color:'white', fontSize:'40px', fontWeight:'bold' }







class TeamPage extends Component {

    render() {

        const MD = '3';
        const LG = '3';
		
		const MDP = '4';
		const LGP = '4';

        return (
            <MDBContainer>
                <MDBJumbotron style={{ marginTop: "1rem" }}>
                    <p>
                        We owe all of our success to our wonderful employees:
                        they’re always on call and looking for ways to get more done in less time.
                    </p>
                    <p>
                        Our employees use innovative database technology that streamlines scheduling
                        and other essential business information, accessing critical data and workflows
                        from a single smartphone app.
                    </p>
                    <p>
                        We continue to innovate by borrowing from years of experience in different industries.
                    </p>
                    <hr className="my-2" />
                    
                </MDBJumbotron>
	
					
					<h1 style={hstyle}>Partners</h1>
					
                    <Row>
                        <Col lg={LGP} md={MDP} className="mb-lg-0 mb-4">
                            <Profile name={'Scott Hammond'} title={'Partner'}
                                img={require('../assets/img/profiles/scott.jpeg')}
                                bio={<div>
                                    |Scott began his career over 20 years ago with a bachelor’s in psychology and a minor in Spanish.
                                    Since then, he’s successfully run several companies crossing through different industries.
                                    At Cascade, Scott oversees all operations, scheduling, sales, and hiring,
                                    with too many other responsibilities to list. He brings an entrepreneurial spirit with him wherever he goes, 
									inspiring coworkers and employees to find solutions where they would never have looked before. 
									Scott currently lives in Alpine, Utah, with his family, dreaming of the day he'll get his chance on 
									Dancing with the Stars.
                                    </div>
                                }
                            />
                        </Col>

                        <Col lg={LGP} md={MDP} className="mb-lg-0 mb-4">

                            <Profile name={'Ben Probst'} title={'Partner'}
                                img={require('../assets/img/profiles/Ben-ProbstWB.jpg')}
                                bio={<div>
                                    |Ben is the contractor’s contractor—he’s done it all.
                                    On the one hand, he’s a real estate expert and has bought and sold hundreds of properties.

                                    But the other hand is strong from years spent framing, tiling,
                                            painting—everything it takes to build a house from nothing.
                                    He spent 15 years building custom homes; his completed projects number in the hundreds.
                                    As a partner at Cascade, Ben takes care of field operations, installation processes, training,
                                    warranties, and asset acquisition and maintenance.
                                    He lives in Lehi, Utah, with his family, exploring the nearby canyons top to bottom.
                                </div>
                                } />

                        </Col>
						
						

                        <Col lg={LGP} md={MDP} className="mb-lg-0 mb-4">
                            <Profile name={'Kyle Widdison'} title={'Partner'}
                                img={require('../assets/img/profiles/kyle.jpeg')}
                                bio={<div>
                                    |Kyle joined Cascade as a partner after 15 years of management, financial strategy, and consulting. 
                                    In 2007 he cofounded Purqz, a property management company, where he served as COO.
                                    He also worked as an instructor for Manhattan GMAT,
                                    preparing the business leaders of tomorrow for what’s to come. 
									
                                    Kyle puts his experience to use today by overseeing all of Cascade’s pre-construction activities,
                                    financial functions, and information systems.
                                    He lives in American Fork, Utah,
                                    playing whatever sport you suggest and building a pen for his herd of eight children and three foster kids.
                                </div>
                                } />

                        </Col>
						
						
					</Row>
					
					<ColoredLine/>
					<h1 style={hstyle}>Sales & Bidding</h1>
					
					<Row>
						<Col lg={LG} md={MD}>

                            <ProfileShort name={'Austin Hammond'} title={'Sales & Account Manager'}
                                img={require('../assets/img/profiles/austin.jpeg')}
                                bio={<div>
                                    |Austin.
                                    </div>
                                } />

                        </Col>
						
						<Col lg={LG} md={MD}>

                            <ProfileShort name={'Dan Probst'} title={'Sales Engineer'}
                                img={require('../assets/img/profiles/dan.jpeg')}
                                bio={<div>
                                    |Dan.
                                    </div>
                                } />

                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Kevin Barton'} title={'Sales Estimating'}
                                img={require('../assets/img/profiles/kevin.jpeg')}
                                bio={<div>
                                    |Kevin.
                                    </div>
                                } />
                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Caden Eagar'} title={'Bidding'}
                                img={require('../assets/img/profiles/black.jpg')}
                                bio={<div>
                                    |Caden.
                                    </div>
                                } />
                        </Col>
					</Row>
					
					
					<ColoredLine/>
					<h1 style={hstyle}>Office Management</h1>
					
					<Row>
						<Col lg={LG} md={MD}>

                            <ProfileShort name={'Monica Perez'} title={'Scheduling'}
                                img={require('../assets/img/profiles/monica.jpeg')}
                                bio={<div>
                                    |Monica.
                                    </div>
                                } />

                        </Col>
						
						<Col lg={LG} md={MD}>

                            <ProfileShort name={'Abe Fisher'} title={'Purchasing'}
                                img={require('../assets/img/profiles/abe.jpeg')}
                                bio={<div>
                                    |Abe plays a vital role in streamlining daily operations, overseeing material procurement, delivery logistics, and purchase order management.
									While collaborating closely with field supervisors to resolve job-specific challenges and ensuring seamless coordination between field and office teams, 
									Abe is also a liaison with vendors to secure optimal pricing and material procurement. With a meticulous eye for detail and a solutions-driven approach, 
									Abe is instrumental in driving project success..
                                    </div>
                                } />

                        </Col>

						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Diego Meda'} title={'Production Estimating'}
                                img={require('../assets/img/profiles/diego.jpeg')}
                                bio={<div>
                                    |Diego.
                                    </div>
                                } />
                        </Col>

						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Mauricio Ferrari'} title={'Warranty'}
                                img={require('../assets/img/profiles/mauricio.jpeg')}
                                bio={<div>
                                    |Mauricio.
                                    </div>
                                } />
                        </Col>
					</Row>
					
					<p></p>
					
					<Row>
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Jared Reeves'} title={'Development & IT'}
                                img={require('../assets/img/profiles/jared.jpeg')}
                                bio={<div>
                                    |Jared.
                                    </div>
                                } />
                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Alex Allen'} title={'Accounts Receivable & Estimating'}
                                img={require('../assets/img/profiles/alexallen.jpeg')}
                                bio={<div>
                                    |Alex.
                                    </div>
                                } />
                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Marilyn Dulong'} title={'Payroll & HR & Inspections'}
                                img={require('../assets/img/profiles/marilyn.jpeg')}
                                bio={<div>
                                    |Marilyn.
                                    </div>
                                } />
                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Carrie Garner'} title={'Accounts Payable'}
                                img={require('../assets/img/profiles/black.jpg')}
                                bio={<div>
                                    |Marilyn.
                                    </div>
                                } />
                        </Col>
						
						
					</Row>
					
					<ColoredLine/>
					<h1 style={hstyle}>Shop & Trucks & Safety</h1>
					
					<Row>
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Fernando Perez'} title={'Safety & Facilities'}
                                img={require('../assets/img/profiles/fernando.jpeg')}
                                bio={<div>
                                    |Fernando.
                                    </div>
                                } />
                        </Col>

						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Hirum Villagrana'} title={'Shop & Special Projects'}
                                img={require('../assets/img/profiles/hirum.jpeg')}
                                bio={<div>
                                    |Hirum.
                                    </div>
                                } />
                        </Col>
						
						<Col lg={LG} md={MD}>
                            <ProfileShort name={'Brad Probst'} title={'DOT Compliance'}
                                img={require('../assets/img/profiles/brad.jpeg')}
                                bio={<div>
                                    |Sirvando.
                                    </div>
                                } />
                        </Col>

					</Row>

					<ColoredLine/>
					<h1 style={hstyle}>Superintendents</h1>

                    <Row>
                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">

                            <ProfileShort name={'Alan Segat'} title={'Head of Construction'}
                                img={require('../assets/img/profiles/alan.jpeg')}
                                bio={<div>
                                    |Alan.
                                    </div>
                                } />

                        </Col>

                        <Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Facundo Curi'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>
						
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Dustin Edelman'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>
						
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Miguel Nunez'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>
                    </Row>
					
					<p></p>
					
					<Row>	
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Danny Cid'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>	

						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Alex Saldana'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>	
						
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Jorge Gonzales'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>
						
						<Col lg={LG} md={MD} className="mb-lg-0 mb-4">
                            <ProfileShort name={'Arturo Ochoa'} title={'Superintendent'}
                                img={require('../assets/img/profiles/black.jpg')}
                                />
                        </Col>
                    </Row>
            
            </MDBContainer>
        )
    }

}

export default TeamPage;